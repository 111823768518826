// jquery-extendext library (https://www.npmjs.com/package/jquery-extendext)
require('jquery-extendext')

// doT library (http://olado.github.io/doT/)
const doT = require('dot')

// jQuery-QueryBuilder library (https://querybuilder.js.org)
require('jQuery-QueryBuilder')
require('jQuery-QueryBuilder/dist/i18n/query-builder.fr.js')
require('jQuery-QueryBuilder/dist/css/query-builder.default.css')




const mermaid = require('mermaid/dist/mermaid.js')


export {
  doT,
  mermaid
}
